import { useState } from "react";
import { Intent, Menu } from "@blueprintjs/core";
import { Text, TextVariant } from "@ui/Text";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { DialogLegacy } from "@components/Dialog";
import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { Popover } from "@components/Popover";
import { Tag } from "@components/Tag";
import { MicrosoftTeam, MicrosoftTeamsEventTypes, MicrosoftTeamsLinkChannelDto, MicrosoftTeamsLinkedTeam } from "@rollup-api/models";

import MicrosoftTeamsSettingsAddNewLink from "./MicrosoftTeamsSettingsAddNewLink";

import styles from "./MicrosoftTeamsSubscriptionList.module.scss";

type Props = {
  subscriptions: Array<MicrosoftTeamsLinkedTeam>;
  teams: Array<MicrosoftTeam>;
  onUnlink: (subscription: MicrosoftTeamsLinkChannelDto) => Promise<void>;
  onUpdate: (teamId: string, channelId: string, eventTypes: MicrosoftTeamsEventTypes[]) => Promise<void>;
};

const MicrosoftTeamsSubscriptionsList = (props: Props) => {
  const { subscriptions, onUnlink, teams, onUpdate } = props;
  const [loading, setLoading] = useState(false);
  const [editingSub, setEditingSub] = useState<MicrosoftTeamsLinkChannelDto | undefined>(undefined);
  const [itemToDelete, setItemToDelete] = useState<MicrosoftTeamsLinkChannelDto | null>(null);
  const channelsList = teams.flatMap(team => team.channels);

  if (!subscriptions.length) {
    return null;
  }

  const getTeamName = (teamId: string) => {
    const team = teams.find(t => t.teamId === teamId);
    if (!team?.teamName) {
      return "Unknown team";
    }
    return team.teamName;
  };

  const getChannelName = (channelId: string) => {
    const channel = channelsList.find(c => c.id === channelId);
    if (!channel?.name) {
      return "Unknown channel";
    }
    return `#${channel.name}`;
  };

  const handleUnlink = async () => {
    if (!itemToDelete) {
      return;
    }

    setLoading(true);
    await onUnlink(itemToDelete);
    setLoading(false);
    setItemToDelete(null);
  };

  const handleEditClick = (sub: MicrosoftTeamsLinkChannelDto) => {
    if (editingSub?.channelId === sub.channelId) {
      setEditingSub(undefined);
    } else {
      setEditingSub(sub);
    }
  };

  const handleUpdate = async (dto: MicrosoftTeamsLinkChannelDto) => {
    setLoading(true);
    await onUpdate(dto.teamId, dto.channelId, dto.entityTypes);
    setLoading(false);
    setEditingSub(undefined);
  };

  const renderSubscription = (linkedChannel: MicrosoftTeamsLinkChannelDto) => {
    if (editingSub?.channelId === linkedChannel.channelId) {
      return (
        <MicrosoftTeamsSettingsAddNewLink
          className={styles.microsoftTeamsSubscriptionListEditMode}
          key={linkedChannel.channelId}
          link={editingSub}
          teams={teams}
          loading={loading}
          onCancel={() => setEditingSub(undefined)}
          subscriptions={subscriptions}
          onSave={handleUpdate}
        />
      );
    }

    return (
      <div className={styles.microsoftTeamsSubscriptionListRow} key={linkedChannel.channelId}>
        <div className={classNames(styles.microsoftTeamsSubscriptionListCell, styles.microsoftTeamsSubscriptionListChannel)}>
          {getTeamName(linkedChannel.teamId)}
        </div>
        <div className={classNames(styles.microsoftTeamsSubscriptionListCell, styles.microsoftTeamsSubscriptionListChannel)}>
          {getChannelName(linkedChannel.channelId)}
        </div>
        <div className={classNames(styles.microsoftTeamsSubscriptionListCell, styles.microsoftTeamsSubscriptionListEvents)}>
          <span className={styles.microsoftTeamsSubscriptionListEventsTitle}>
            {linkedChannel.entityTypes.map(entity => (
              <Tag className={styles.microsoftTeamsSubscriptionListEventsTitleTag} key={entity}>
                {entity}
              </Tag>
            ))}
          </span>
          <div>
            <Popover
              content={
                <Menu>
                  <MenuItem e2eIdentifiers="edit" icon="edit" text="Edit" onClick={() => handleEditClick(linkedChannel)} />
                  <MenuItemDelete onDelete={() => setItemToDelete(linkedChannel)} />
                </Menu>
              }
            >
              <Button minimal icon="more" e2eIdentifiers="subscription-menu" />
            </Popover>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.microsoftTeamsSubscriptionList}>
      <div className={styles.microsoftTeamsSubscriptionListHeader}>
        <div className={classNames(styles.microsoftTeamsSubscriptionListCell, styles.microsoftTeamsSubscriptionListChannel)}>Channel</div>
        <div className={styles.microsoftTeamsSubscriptionListCell}>Event types</div>
      </div>
      {subscriptions.map(linkedTeam =>
        linkedTeam.channels.map(i => renderSubscription({ ...i, channelId: i.id, teamId: linkedTeam.teamId }))
      )}
      {itemToDelete && (
        <DialogLegacy
          isCloseButtonShown
          isOpen
          title={<Text variant={TextVariant.H3}>Delete "{getChannelName(itemToDelete.channelId)}" channel subscription?</Text>}
          onClose={() => setItemToDelete(null)}
        >
          <div className={styles.microsoftTeamsSubscriptionListDialog}>
            <Button
              className={styles.microsoftTeamsSubscriptionListAction}
              large
              minimal
              disabled={loading}
              outlined
              onClick={() => setItemToDelete(null)}
              e2eIdentifiers="delete"
            >
              No
            </Button>
            <Button
              className={styles.microsoftTeamsSubscriptionListAction}
              large
              loading={loading}
              intent={Intent.PRIMARY}
              onClick={handleUnlink}
              e2eIdentifiers="cancel"
            >
              Yes
            </Button>
          </div>
        </DialogLegacy>
      )}
    </div>
  );
};

export default observer(MicrosoftTeamsSubscriptionsList);
