import { observer } from "mobx-react";

import { ExecutionTypeIcon } from "@components/Analysis";
import { Icon } from "@components/Icon";
import { MenuItem } from "@components/MenuItem";
import AttachmentIcon from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentIcon";
import { UnknownUserTag } from "@components/Reports/Editor/Extentions/Mention/MentionTag/UnknownUserTag";
import { getInfoFromId } from "@components/Reports/Editor/Extentions/Mention/MentionUtils";
import { IMentionItem, MentionType } from "@components/Reports/Editor/Extentions/Mention/types";
import UserInfo from "@components/UserInfo/UserInfo";
import appStore from "@store/AppStore";

import styles from "./MentionItem.module.scss";

interface IMentionItemProps {
  item: IMentionItem;
  selectedItem?: IMentionItem;
  onClick(item: IMentionItem): void;
}

const MentionItem = (props: IMentionItemProps) => {
  const { item, selectedItem, onClick } = props;
  const workspace = appStore.workspaceModel;

  const getIcon = (item: IMentionItem) => {
    if (item.type === MentionType.Attachment) {
      const { id } = getInfoFromId(item.id);
      const attachment = workspace?.attachments.get(id);
      if (attachment) {
        return <AttachmentIcon attachment={workspace?.attachments.get(getInfoFromId(item.id).id)} height={16} width={16} />;
      }
    } else if (item.type === MentionType.User) {
      const { id } = getInfoFromId(item.id);
      const user = appStore.orgModel.info.userWithId(id);
      return user ? <UserInfo user={user} avatarOnly /> : <UnknownUserTag avatarOnly />;
    } else if (item.type === MentionType.Report) {
      const { id } = getInfoFromId(item.id);
      const report = workspace?.reportsMap.get(id);
      if (report) {
        return <div>{report.displayedIcon}</div>;
      }
    } else if (item.type === MentionType.AnalysisOutput) {
      const { id } = getInfoFromId(item.id);
      const analysisOutput = workspace?.analysis.analysisOutputMap.get(id);
      if (analysisOutput?.analysis) {
        return <ExecutionTypeIcon className={styles.mentionItemDevIcon} type={analysisOutput.analysis.type} />;
      }
    }
    return <Icon icon={item.icon} />;
  };

  return (
    <MenuItem
      active={item === selectedItem}
      key={item.id}
      onClick={() => onClick(item)}
      icon={getIcon(item)}
      text={item.extendedLabel ?? item.label}
      e2eIdentifiers={[item.extendedLabel ?? item.label]}
    />
  );
};

export default observer(MentionItem);
