import { Intent } from "@blueprintjs/core";
import { IRowNode } from "ag-grid-community";
import { cast, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { CreateBlockDto } from "@rollup-api/models";
import { ThumbnailStatus } from "@rollup-api/models/catalogItem/catalogItemDtos";
import { Import, ImportLogEntry, ImportStatus, ImportType, UpdateImportStatusDto } from "@rollup-api/models/import";
import { getBlockById, statusIntent, statusLabel } from "@utilities";
import { isDefined } from "@utilities/TypeGuards";

import { rollupClient } from "../core/api";

import appStore from "./AppStore";

const TOTAL_PROGRESS_STEPS = 5;

export enum ImportEvents {
  CreateImport = "createImport",
  ImportStatusChanges = "importStatusChanged",
  PdmImportCadNodeImagesReady = "pdmImportCadNodeImagesReady",
}

export const ImportStore = types
  .model("Import", {
    id: types.identifier,
    originalFileName: types.string,
    logs: types.array(types.frozen<ImportLogEntry>()),
    blocks: types.array(types.frozen<CreateBlockDto>()),
    columnMap: types.maybeNull(types.string), // JSON string
    createdBy: types.string,
    createdAt: types.optional(types.number, Date.now()),
    updatedAt: types.optional(types.number, Date.now()),
    attachmentId: types.string,
    type: types.enumeration("ImportType", [...Object.values(ImportType)]),
    status: types.optional(types.enumeration("ImportStatus", [...Object.values(ImportStatus)]), ImportStatus.InProgress),
  })
  .views(self => ({
    get progress(): number {
      switch (self.status) {
        case ImportStatus.Extracting:
          return 2;
        case ImportStatus.Processing:
          return 3;
        case ImportStatus.CreatingEntities:
          return 4;
        case ImportStatus.Failed:
        case ImportStatus.Success:
          return 5;
        default:
          return 1;
      }
    },
  }))
  .actions(self => ({
    setStatus(status: ImportStatus) {
      self.status = status;
    },
    setLogs(logs: ImportLogEntry[]) {
      self.logs = cast(logs);
    },
    setBlocks(blocks: CreateBlockDto[]) {
      self.blocks = cast(blocks);
    },
    preview() {
      appStore.ui.setReviewImportId(self.id);
    },
    finish(blocks: CreateBlockDto[], workspaceId?: string) {
      self.status = ImportStatus.CreatingEntities;
      rollupClient.imports.finish({ importId: self.id, blocks, workspaceId });
      self.blocks = cast([]);
    },
  }))
  .views(self => ({
    get progressPercentage(): number {
      return self.progress / TOTAL_PROGRESS_STEPS;
    },
    get statusLabel(): string {
      return statusLabel(self.status);
    },
    get statusIntent(): Intent {
      return statusIntent(self.status);
    },
  }));

export function subscribeToImportEvents(socket: Socket) {
  socket.on(
    ImportEvents.ImportStatusChanges,
    (data: { orgId: string; workspaceId: string; updatedImportStatusDto: UpdateImportStatusDto }) => {
      const { id, status, type, entities, logs, bomTable, blocksDto } = data.updatedImportStatusDto;
      if (id && data.orgId === appStore.orgModel?.info?.id) {
        if (entities?.catalogItems?.length && entities.catalogItemVersions?.length) {
          entities?.catalogItems?.forEach(catalogItem => {
            const catalogItemVersion = entities.catalogItemVersions?.find(v => v.catalogItemId === catalogItem.id);
            catalogItemVersion && appStore.orgModel.catalogItems.addExistingCatalogItemWithVersion({ catalogItem, catalogItemVersion });
          });
        }

        if (entities?.catalogItemReference?.length) {
          entities.catalogItemReference.forEach(reference => {
            appStore.orgModel.catalogItems.addCatalogItemReference(reference, false);
          });
        }

        const importEntity = appStore.orgModel.importsMap.get(id);
        importEntity?.setStatus(status);
        logs && importEntity?.setLogs(logs);
      }

      if (id && data.workspaceId === appStore.workspaceModel?.id) {
        const importEntity = appStore.workspaceModel.importsMap.get(id);
        importEntity?.setStatus(status);
        logs && importEntity?.setLogs(logs);

        if (status === ImportStatus.Success && !appStore.workspaceModel.importsInProgress.length) {
          appStore.ui.hideUploadQueueWindow();
        }

        if (blocksDto?.length) {
          importEntity?.setBlocks(blocksDto);
        }

        if (entities?.blocks?.length) {
          appStore.workspaceModel.addExistingBlocks(entities.blocks);
        }

        if (entities?.propertyInstances?.length) {
          appStore.workspaceModel.addExistingPropertyInstances(entities.propertyInstances);
        }

        if (entities?.statusInstances?.length) {
          appStore.workspaceModel.addExistingStatusInstances(entities.statusInstances);
        }

        if (type === ImportType.Bom && bomTable) {
          const existingTable = appStore.workspaceModel.bomTablesMap.get(bomTable.id);
          if (existingTable && bomTable?.rows?.length) {
            const validRows = bomTable.rows.filter(r => !!getBlockById(r));
            existingTable.setRows(validRows, false);
          } else {
            appStore.workspaceModel.handleBomTableCreated(bomTable);
          }
        }

        if (type === ImportType.Requirement && entities?.requirementBlocks?.length) {
          appStore.workspaceModel.requirementsModule.addExistingRequirementBlocks(entities.requirementBlocks);
        }
      }
    }
  );

  socket.on(ImportEvents.CreateImport, (data: { workspaceId: string; createImportDto: Import }) => {
    if (data.createImportDto.id) {
      if (data.workspaceId === appStore.workspaceModel?.id) {
        appStore.workspaceModel.addExistingImport(data.createImportDto);
      } else if (data.workspaceId === appStore.orgModel?.info?.id) {
        appStore.orgModel.addExistingImport(data.createImportDto);
      }
    }
  });

  socket.on(ImportEvents.PdmImportCadNodeImagesReady, (data: { orgId: string; catalogItemIds: string[] }) => {
    const { orgId, catalogItemIds } = data;
    if (orgId === appStore.orgModel?.info?.id && appStore.env.catalogItemTableGridApi) {
      const catalogItems = catalogItemIds.map(appStore.orgModel.catalogItems.getCatalogItem).filter(isDefined);
      catalogItems.forEach(catalogItem => catalogItem.versions[0]?.metaData.setThumbnailStatus(ThumbnailStatus.Ready));
      const rowNodes = catalogItems
        .map(c => appStore.env.catalogItemTableGridApi!.getRowNode(c.id))
        .filter(possibleNode => !!possibleNode) as IRowNode[];
      if (catalogItems.length && rowNodes.length) {
        appStore.env.catalogItemTableGridApi.redrawRows({ rowNodes });
      }
    }
  });
}

export interface IImport extends Instance<typeof ImportStore> {}
export interface IImportSnapshotIn extends SnapshotIn<typeof ImportStore> {}
interface IImportSnapshotOut extends SnapshotOut<typeof ImportStore> {}
export interface IImportMobxType extends IType<IImportSnapshotIn, IImportSnapshotOut, IImport> {}
