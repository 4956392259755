import { CSSProperties } from "react";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { observer } from "mobx-react";

import { CustomIcon } from "@components/CustomIcon";
import DevIcon from "@components/DevIcon";
import { IconSource, IIcon } from "@rollup-types/icons";

interface IIcon2Props {
  icon?: IIcon;
  className?: string;
  size?: number;
  style?: CSSProperties;
  active?: boolean;
  activeWithBlueBg?: boolean;
  onClick?(): void;
}

const Icon = (props: IIcon2Props) => {
  const { icon, className, style, active, activeWithBlueBg } = props;
  const { size, onClick } = props;

  switch (icon?.source) {
    case IconSource.DevIcon:
      return <DevIcon className={className} style={style} icon={icon.name} color={icon.color} size={size ?? 16} onClick={onClick} />;
    case IconSource.Custom:
      return (
        <CustomIcon
          className={className}
          style={style}
          activeWithBlueBg={activeWithBlueBg}
          active={active}
          icon={icon.name}
          size={size}
          onClick={onClick}
        />
      );
    case IconSource.Blueprint:
    default:
      return <BlueprintIcon className={className} style={style} icon={icon?.name} color={icon?.color} size={size} onClick={onClick} />;
  }
};

export default observer(Icon);
