import { KeyboardEvent, useCallback } from "react";
import { Editor } from "@tiptap/react";
import { v4 as uuidV4 } from "uuid";

import { allContentExtensions, TIPTAP_EMPTY_CONTENT } from "@components/Reports/Editor/constants";
import { handleBackspaceIfMention } from "@components/Reports/Editor/Extentions/Mention/MentionUtils";
import { endPositionFixer, startPositionFixer } from "@components/Reports/Editor/useEditorHandlers";
import { focusReportBlockById } from "@components/Reports/Editor/utils";
import { IDocumentBlock } from "@components/Requirements/RequirementsDocumentView/DocumentEditor/DocumentBlock.types";
import { ARROW_DOWN_KEY, ARROW_UP_KEY, BACKSPACE_KEY, ENTER_KEY } from "@constants/keys";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { IReportBlock } from "@store/ReportBlockStore";
import { focusNextBlock, focusPreviousBlock } from "@utilities/TipTap";

interface IUseDocumentBlockHandlersParams {
  documentBlock: IDocumentBlock;
  documentBlocks: IDocumentBlock[];
  editor?: Editor | null;
  onFocus?(): void;
  onAddBlock(type: RollupEditorType, label: string, orderIndex: number): string | undefined;
}
export const useDocumentBlockHandlers = (params: IUseDocumentBlockHandlersParams) => {
  const { documentBlock, documentBlocks, onAddBlock, onFocus } = params;
  const editor = params.editor ?? appStore.env.editors.get(documentBlock.id);

  const deleteEmptyBlock = () => {
    if (editor?.isEmpty) {
      focusPreviousBlock(documentBlock, documentBlocks);
      documentBlock.delete();
    }
  };

  const createNewBlock = useCallback(
    (type = RollupEditorType.p, label = "", above = false, propsBlock?: IReportBlock) => {
      const block = propsBlock || documentBlock;

      const indexModifier = above ? 0 : 1;
      const orderIndex = documentBlocks.findIndex(b => b.id === block.id) + indexModifier;
      const id = uuidV4();
      const newBlockId = onAddBlock(type, label, orderIndex);
      const focusStart = above || label;
      focusReportBlockById(above ? block.id : id, !focusStart);
      return newBlockId;
    },
    [documentBlock, documentBlocks, onAddBlock]
  );

  const handleEnterKeyDown = (event: KeyboardEvent<HTMLDivElement>, type?: RollupEditorType) => {
    if (event.key === ENTER_KEY && !event.shiftKey) {
      const cursorPosition = editor?.state.selection.$anchor.pos || 1;
      const isAbove = editor?.state.selection.$anchor.pos === 1 && !editor?.isEmpty;
      const doc = editor?.state?.doc;
      let label = "";

      if (doc && !isAbove) {
        const textBefore = doc.cut(0, cursorPosition).toJSON();
        const content = doc.cut(cursorPosition, doc.content.size).toJSON();
        const temporaryEditorInstance = new Editor({ content, extensions: allContentExtensions });
        const temporaryContent = temporaryEditorInstance.getHTML();
        const temporaryText = temporaryEditorInstance.getText();
        if (temporaryText && TIPTAP_EMPTY_CONTENT !== temporaryContent) {
          label = temporaryContent;
        }
        editor?.commands.setContent(textBefore);
      }

      createNewBlock(type, label, isAbove);
    }
  };

  const handleModEnterKeyDown = (event: KeyboardEvent<HTMLDivElement>, type?: RollupEditorType, label?: string) => {
    if ((event.key === ENTER_KEY && event.metaKey) || (event.key === ENTER_KEY && event.ctrlKey)) {
      createNewBlock(type, label);
    }
  };

  const handleShiftEnterKeyDown = (event: KeyboardEvent<HTMLDivElement>, type?: RollupEditorType, label?: string) => {
    if (event.key === ENTER_KEY && event.shiftKey) {
      createNewBlock(type, label);
    }
  };

  const handleBackspaceKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== BACKSPACE_KEY || !editor) {
      return;
    }

    const isMention = handleBackspaceIfMention({ editor });
    if (isMention) {
      return;
    }

    if (documentBlock.type !== RollupEditorType.p && editor?.isEmpty) {
      documentBlock.updateType(RollupEditorType.p);
      return;
    }

    if (documentBlocks.length > 1) {
      deleteEmptyBlock();
    }
  };

  const handleArrowDownKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ARROW_DOWN_KEY && documentBlocks.length > 1) {
      const endPosition = endPositionFixer(documentBlock.type);
      const canJumpUp = (editor?.getText().length || 0) + endPosition <= (editor?.state.selection.$anchor.pos || 0);
      canJumpUp && focusNextBlock(documentBlock, documentBlocks);
    }
  };

  const handleArrowUpKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ARROW_UP_KEY && documentBlocks.length > 1) {
      const startPosition = startPositionFixer(documentBlock.type);
      const canJumpUp = editor?.state.selection.$anchor.pos === startPosition || editor?.isEmpty;
      canJumpUp && focusPreviousBlock(documentBlock, documentBlocks);
    }
  };

  const handleEditorReady = useCallback(
    (editor: Editor | null) => {
      if (editor) {
        appStore.env.editors.set(documentBlock.id, editor);
      }
    },
    [documentBlock.id]
  );

  const handleFocus = () => {
    appStore.env.setActiveDocumentBlockId(documentBlock.id);
    onFocus?.();
  };

  const handleBlur = () => {
    appStore.env.clearActiveDocumentBlockId();
  };

  return {
    createNewBlock,
    focusPreviousBlock,
    handleEditorReady,
    handleFocus,
    handleBlur,
    handleEnterKeyDown,
    handleShiftEnterKeyDown,
    handleModEnterKeyDown,
    handleBackspaceKeyDown,
    handleArrowUpKey,
    handleArrowDownKey,
  };
};
