import { useCallback, useEffect } from "react";
import { Icon, Tooltip } from "@blueprintjs/core";
import { Column } from "ag-grid-community";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { ACTIONS_COL_ID, CREATE_NEW_COL_ID, NAME_COLUMN } from "@components/Modeling/ModelingFrame/Table/TableComponent/constants";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { ITableViewConfig } from "@store/TableViewConfigStore";
import { sanitizeFilename } from "@utilities";

import { defaultTableViewConfigTabId, defaultTableViewLabel, untitledViewLabel } from "./constants";
import { TabNav } from "./TabNav";

import styles from "./TableConfigTabs.module.scss";

type Props = {
  activeTabId?: string;
  tableViewConfigs?: Array<ITableViewConfig>;
};

const TableConfigTabs = (props: Props) => {
  const { tableViewConfigs, activeTabId } = props;
  const activeTab = tableViewConfigs?.find(tab => tab.id === activeTabId);

  useEffect(() => {
    appStore.workspaceModel?.fetchTableViewConfigs();
  }, []);

  const handleAddTab = () => {
    const config = appStore.env.tableView.ui.commonTableColumnsConfig;
    appStore.workspaceModel?.addNewTableViewConfig(config, true, "Untitled View");
  };

  const handleExport = () => {
    if (!appStore.env.tableViewGridApi) {
      return;
    }

    const fileName = activeTab ? `${sanitizeFilename(activeTab.label)}.csv` : "table-view-everything.csv";
    const columnKeys =
      appStore.env.tableViewGridApi.getColumns()?.filter((column: Column) => {
        const colId = column.getColId();
        return colId !== ACTIONS_COL_ID && colId !== CREATE_NEW_COL_ID && column.isVisible();
      }) || [];
    appStore.env.tableViewGridApi.exportDataAsCsv({
      fileName,
      columnKeys: [NAME_COLUMN, ...columnKeys],
    });
  };

  const renderTab = useCallback(
    (tab: ITableViewConfig, isMain?: boolean) => (
      <div
        key={tab.id}
        className={classNames(styles.tableConfigTab, { [styles.tableConfigTabActive]: tab.id === activeTabId })}
        onMouseDown={() => appStore.env.setActiveTableConfigId(tab.id)}
      >
        {isMain && <Icon className={styles.tableConfigTabIcon} icon="pin" />}
        <span className={classNames(styles.tableConfigTabTitle, { [styles.tableConfigTabTitleMuted]: tab.label === untitledViewLabel })}>
          {tab.label}
        </span>
        <Popover key={tab.id} content={<TabNav tab={tab} isMain={isMain} />}>
          <Button
            onClick={e => e.preventDefault()}
            className={styles.tableConfigTabNav}
            small
            minimal
            e2eIdentifiers={["tab_nav"]}
            icon="more"
          />
        </Popover>
      </div>
    ),
    [activeTabId]
  );

  const renderAddMore = () => (
    <div className={styles.tableConfigTabsMoreTooltipContainer}>
      <Tooltip content="Add new view">
        <Button className={styles.tableConfigTabsMore} minimal icon="plus" onClick={handleAddTab} e2eIdentifiers="add-tab" />
      </Tooltip>
    </div>
  );

  return (
    <div className={styles.tableConfigTabsWrapper}>
      <div className={styles.tableConfigTabs}>
        {/* @todo add reorder - table view configs iteration 3 */}
        {renderTab({ label: defaultTableViewLabel, id: defaultTableViewConfigTabId } as ITableViewConfig, true)}
        {tableViewConfigs?.map(tab => renderTab(tab))}
        {renderAddMore()}
      </div>
      <Button onClick={handleExport} className={styles.tableConfigTabsExport} minimal e2eIdentifiers="export-table-view" icon="export">
        Export
      </Button>
    </div>
  );
};

export default observer(TableConfigTabs);
