import { ReactElement } from "react";

import PMBacklogIcon from "@assets/icons/pm/pm-backlog.svg?react";
import PMDoneIcon from "@assets/icons/pm/pm-done.svg?react";
import PMInProgressIcon from "@assets/icons/pm/pm-in-progress.svg?react";
import CustomIcon from "@components/Shared/LegacyCustomIcon";

export enum ProjectStatus {
  Backlog = "Backlog",
  InProgress = "InProgress",
  Done = "Done",
}

export const projectStatusIcons: { [key in ProjectStatus]: ReactElement } = {
  [ProjectStatus.Backlog]: <CustomIcon icon={<PMBacklogIcon />} />,
  [ProjectStatus.InProgress]: <CustomIcon icon={<PMInProgressIcon />} />,
  [ProjectStatus.Done]: <CustomIcon icon={<PMDoneIcon />} />,
};
