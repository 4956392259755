import { useEffect, useState } from "react";
import { useSearchParam } from "@hooks/useSearchParam/useSearchParam";
import { CustomCellRendererProps } from "ag-grid-react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { CommentIcon, CustomIcon } from "@components/CustomIcon";
import { EntityAnnotationListPopover } from "@components/EntityAnnotationListPopover";
import { IAnnotationList } from "@store/AnnotationListStore";
import { StoreType } from "@store/types";

import styles from "./CommentCellRenderer.module.scss";

export interface ICommentCellRendererValue {
  entityId: string;
  annotationList?: IAnnotationList;
}

export interface ICommentCellRendererParams {
  lastFocusedCommentId?: string;
  onFocusComment(commentId: string): void;
  getEntityIdsWithComments(): string[];
}

interface ICommentCellRendererProps extends ICommentCellRendererParams, CustomCellRendererProps<any, ICommentCellRendererValue> {}

const CommentCellRenderer = (props: ICommentCellRendererProps) => {
  const { value, lastFocusedCommentId, onFocusComment, getEntityIdsWithComments } = props;
  const { annotationList, entityId } = value ?? {};
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [annotationId] = useSearchParam("annId");
  const [commentId] = useSearchParam("commId");
  const hasValidAnnotation = !!(annotationId && annotationList?.hasAnnotation(annotationId));
  const hasComment = !!annotationList?.annotations[0]?.hasComment;
  const hasFocusedOnComment = commentId === lastFocusedCommentId;

  useEffect(() => {
    if (hasValidAnnotation && !annotationList?.isPopulatingAnnotations && !hasFocusedOnComment) {
      setIsPopoverOpen(true);
    }
  }, [annotationList?.isPopulatingAnnotations, hasValidAnnotation, hasFocusedOnComment]);

  if (!annotationList || !entityId) {
    return null;
  }

  return (
    <div className={styles.commentCellRenderer} data-testid="commentCell">
      <EntityAnnotationListPopover
        annotationList={annotationList}
        entityId={entityId}
        entityIdsWithComments={getEntityIdsWithComments()}
        storeType={StoreType.RequirementBlock}
        isOpen={isPopoverOpen}
        onInteraction={setIsPopoverOpen}
        lastFocusedCommentId={lastFocusedCommentId}
        onFocusComment={onFocusComment}
      >
        <Button
          className={classNames(styles.commentCellRendererButton, { [styles.commentCellButtonAlwaysVisible]: hasComment || isPopoverOpen })}
          icon={hasComment ? "comment" : <CustomIcon icon={CommentIcon.AddComment} size={16} active />}
          e2eIdentifiers="open-comment-popover"
          minimal
        />
      </EntityAnnotationListPopover>
    </div>
  );
};

export default observer(CommentCellRenderer);
