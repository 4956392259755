import { useEffect, useState } from "react";
import { HTMLTable, NonIdealState, Pre, Tab, Tabs } from "@blueprintjs/core";
import sortBy from "lodash/sortBy";
import { observer } from "mobx-react";

import User from "@components/User";
import { ArtifactInfo, ExecutionResult } from "@rollup-api/models/analysis";
import { isExecutionStatusFinal } from "@rollup-api/models/execution-environments";
import { AnalysisType, IAnalysis } from "@store/Analysis/AnalysisStore";
import { formatFileSize, humanReadableDateTime } from "@utilities";
import { rollupClient } from "src/core/api";

import TextArtifactLog from "./TextArtifactLog";

import "./AnalysisFooter.scss";

export type AnalysisFooterProps = {
  result?: ExecutionResult | null;
  analysis: IAnalysis;
};

enum FooterTab {
  Summary = "summary",
  Output = "output",
  Artifacts = "artifacts",
  Error = "error",
}

const checkArtifacts = (result?: ExecutionResult | null) => {
  let outputLog: ArtifactInfo | undefined;
  let errorLog: ArtifactInfo | undefined;
  const otherArtifacts = new Array<ArtifactInfo>();

  if (result?.artifacts?.length) {
    for (const artifact of result.artifacts) {
      if (artifact.filename === "stdout") {
        outputLog = artifact;
      } else if (artifact.filename === "stderr") {
        errorLog = artifact;
      } else {
        otherArtifacts.push(artifact);
      }
    }
  }

  return { outputLog, errorLog, artifacts: otherArtifacts?.length ? otherArtifacts : undefined };
};

function AnalysisFooter({ result, analysis }: AnalysisFooterProps) {
  const [currentTab, setCurrentTab] = useState<FooterTab>(FooterTab.Summary);

  useEffect(() => {
    setCurrentTab(FooterTab.Summary);
  }, [result?.id, result?.status]);

  const isCodeBlock = analysis.analysisType === AnalysisType.CodeBlock;

  const { outputLog, errorLog, artifacts } = checkArtifacts(result);

  const renderCompleteResult = (result: ExecutionResult) => {
    return (
      <Pre>
        {result.status === "success"
          ? `${analysis.label} ${isCodeBlock ? "executed" : "calculated"} successfully`
          : `${isCodeBlock ? "Execution" : "Calculation"}  failed with exit code ${result.statusCode}`}
        {result.errorMessage && (
          <>
            <br />
            {result.errorMessage}
          </>
        )}
        <br />
        <br />
        Run by: <User userId={result.createdBy} />
        <br />
        Start time: {humanReadableDateTime(result.createdAt)}
        <br />
        Preparation duration: {result.preparationTime?.toFixed(1)} ms
        <br />
        Execution duration: {result.executionTime?.toFixed(1)} ms
        <br />
        Total billable: {((result.preparationTime ?? 0) + (result.executionTime ?? 0))?.toFixed(1)} ms
      </Pre>
    );
  };

  const summaryPanel = result ? (
    <div className="analysis-footer--summary">
      {result && isExecutionStatusFinal(result.status) ? renderCompleteResult(result) : <NonIdealState title="Execution in progress" />}
    </div>
  ) : (
    <NonIdealState title={`Press Run to ${isCodeBlock ? "execute code" : "calculate spreadsheet"}`} />
  );

  const stdoutPanel =
    result && outputLog ? (
      <div className="analysis-footer--stdout">
        <TextArtifactLog result={result} artifact={outputLog} />
      </div>
    ) : undefined;

  const stderrPanel =
    result && errorLog ? (
      <div className="analysis-footer--stderr">
        <TextArtifactLog result={result} artifact={errorLog} />
      </div>
    ) : undefined;

  const openArtifact = (artifact: ArtifactInfo) => {
    if (result) {
      rollupClient.analysisModule.analyses
        .getAssetUrl(analysis.id, result.id, artifact.id)
        .then(res => window.open(res.data.url, "_blank"));
    }
  };

  // TODO: Clean up this interface
  const artifactsPanel = (
    <div className="analysis-footer--artifacts">
      <HTMLTable striped compact>
        <thead>
          <tr>
            <th>Filename</th>
            <th className="size-column">Size</th>
            <th className="mime-type-column">MIME type</th>
          </tr>
        </thead>
        <tbody>
          {sortBy(artifacts, "filename")?.map(artifact => (
            <tr key={artifact.id} onClick={() => openArtifact(artifact)}>
              <td>{artifact.filename}</td>
              <td>{formatFileSize(artifact.size)}</td>
              <td>{artifact.mimeType}</td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </div>
  );

  return (
    <Tabs
      id="analysis-footer-tabs"
      className="analysis-footer"
      selectedTabId={currentTab}
      renderActiveTabPanelOnly
      onChange={tabId => setCurrentTab(tabId as FooterTab)}
    >
      <Tab id={FooterTab.Summary} icon="info-sign" title="Summary" panel={summaryPanel} />
      {isCodeBlock && <Tab id={FooterTab.Artifacts} icon="media" title="Artifacts" panel={artifactsPanel} disabled={!artifacts} />}
      {isCodeBlock && <Tab id={FooterTab.Output} icon="console" title="Output log" panel={stdoutPanel} disabled={!outputLog} />}
      <Tab id={FooterTab.Error} icon="error" title="Error log" panel={stderrPanel} disabled={!errorLog} />
    </Tabs>
  );
}

export default observer(AnalysisFooter);
