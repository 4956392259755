import { JSX, MouseEventHandler, ReactNode, useEffect, useRef, useState } from "react";
import { Icon } from "@blueprintjs/core";
import { MaybeElement } from "@blueprintjs/core/src/common/props";
import type { IconName } from "@blueprintjs/icons";
import { ItemRendererProps } from "@blueprintjs/select/src/common/itemRenderer";
import Text, { TextVariant } from "@ui/Text";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useResizeObserver } from "usehooks-ts";

import { MultiSelect } from "@components/MultiSelect";
import { Tooltip } from "@components/Tooltip";
import { createDataTestId, ElementType, IE2EIdentifiers } from "@utilities/E2EUtils";

import styles from "./MultiSelectTag.module.scss";

export type MultiSelectTagPropsItem = {
  label: string;
  id: string; // we want to be able to render similar items twice
  icon?: IconName | MaybeElement;
  color?: string;
  tooltip?: string;
  e2eIdentifiers?: IE2EIdentifiers;
};

export type MultiSelectTagProps = {
  items: MultiSelectTagPropsItem[];
  compact?: boolean;
  className?: string;
  containerClassName?: string;
  isPopoverOpen?: boolean;
  portalContainer?: HTMLElement;
  onInteraction?: (nextState: boolean) => void;
  createNewItemRenderer?: (query: string, active: boolean, handleClick: MouseEventHandler<HTMLElement>) => JSX.Element | undefined;
  selectedItems: MultiSelectTagPropsItem[];
  onItemRemove: (item: MultiSelectTagPropsItem) => void;
  onItemSelect: (item: MultiSelectTagPropsItem) => void;
  disabled?: boolean;
  e2eIdentifiers?: IE2EIdentifiers;
  customTarget?: (selectedItems: MultiSelectTagPropsItem[], isOpen: boolean) => ReactNode;
  customItemRenderer?: (item: MultiSelectTagPropsItem, itemProps?: ItemRendererProps) => JSX.Element | null;
};

const MultiSelectTag = (props: MultiSelectTagProps) => {
  const { items, disabled, selectedItems, compact, className, containerClassName, portalContainer, isPopoverOpen, e2eIdentifiers } = props;
  const { onInteraction, onItemSelect, customTarget, customItemRenderer, createNewItemRenderer } = props;
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const itemsResultRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState("");
  const [showEllipsis, setShowEllipsis] = useState(false);
  const { width: containerWidth } = useResizeObserver({ ref: itemsContainerRef });

  useEffect(() => {
    if (itemsContainerRef.current && itemsResultRef.current) {
      setShowEllipsis(itemsContainerRef.current.scrollWidth < itemsResultRef.current.scrollWidth + 43);
    }
  }, [selectedItems.length, containerWidth]);

  const renderCustomTarget = () => (
    <div
      ref={itemsContainerRef}
      className={classNames(styles.multiSelectTagSelectedItemsContainer, containerClassName, {
        [styles.multiSelectTagSelectedItemsContainerEmpty]: !selectedItems.length,
      })}
      {...(e2eIdentifiers ? createDataTestId(ElementType.Button, e2eIdentifiers) : undefined)}
    >
      <div ref={itemsResultRef} className={styles.multiSelectTagSelectedItemsResult}>
        {selectedItems.length ? selectedItems.map(i => itemRenderer(i)) : <Text variant={TextVariant.Caption}>Select</Text>}
      </div>
      {showEllipsis && <span className={styles.multiSelectTagEllipsis}>...</span>}
      <Icon className={styles.multiSelectTagIcon} icon="chevron-down" />
    </div>
  );

  const tagRenderer = (item: MultiSelectTagPropsItem) => (
    <div className={styles.multiSelectTagSelectedItem}>
      <Icon icon={item.icon} />
      <span className={styles.multiSelectTagSelectedItemText}>{item.label}</span>
    </div>
  );

  const itemRenderer = (item: MultiSelectTagPropsItem, itemProps?: ItemRendererProps) => {
    return (
      <Tooltip className={styles.multiSelectTagListItemTooltip} content={item.tooltip ?? ""} disabled={!item.tooltip}>
        <div
          key={item.id}
          onClick={itemProps?.handleClick}
          className={classNames(styles.multiSelectTagListItem, {
            [styles.multiSelectTagListItemInactive]: !itemProps,
            [styles.multiSelectTagListItemSelected]: itemProps && selectedItems.some(i => i.id === item.id),
          })}
          {...(item.e2eIdentifiers ? createDataTestId(ElementType.MenuItem, `dropdown-menu-item-${item.e2eIdentifiers}`) : undefined)}
        >
          <Icon icon={item.icon} />
          <span className={styles.multiSelectTagListItemText}>{item.label}</span>
        </div>
      </Tooltip>
    );
  };

  const itemPredicate = (searchQuery: string, item: MultiSelectTagPropsItem) => {
    return item.label.toLowerCase().includes(searchQuery.toLowerCase());
  };

  const getTagProps = (_a: any, index: number) => {
    return { style: { backgroundColor: selectedItems[index].color } };
  };

  return (
    <MultiSelect
      className={classNames(styles.multiSelectTag, className)}
      tagRenderer={tagRenderer}
      selectedItems={selectedItems}
      onItemSelect={onItemSelect}
      items={items}
      createNewItemRenderer={createNewItemRenderer}
      noResults={<Text variant={TextVariant.Caption}>{createNewItemRenderer ? "Start typing to create a new option" : "No results"}</Text>}
      itemPredicate={itemPredicate}
      onRemove={props.onItemRemove}
      onQueryChange={setQuery}
      query={query}
      createNewItemFromQuery={createNewItemRenderer ? label => ({ label, id: label }) : undefined}
      popoverContentProps={{ className: styles.multiSelectTagPopoverContent }}
      popoverProps={{
        minimal: true,
        matchTargetWidth: false,
        popoverClassName: styles.multiSelectTagPopover,
        isOpen: isPopoverOpen,
        onInteraction,
        portalContainer,
      }}
      tagInputProps={{
        className: classNames(styles.multiSelectTagInput, {
          [styles.multiSelectTagInputEmpty]: !query && selectedItems.length,
          [styles.multiSelectTagInputCompact]: compact,
        }),
        leftIcon: "search",
        tagProps: getTagProps,
      }}
      itemRenderer={customItemRenderer || itemRenderer}
      customTarget={customTarget || renderCustomTarget}
      disabled={disabled}
    />
  );
};

export default observer(MultiSelectTag);
