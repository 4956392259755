import assignIn from "lodash/assignIn";
import isNull from "lodash/isNull";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import { cast, IAnyModelType, Instance, isAlive, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

import { BomTableGridApi } from "@components/BomTables/Table/types";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import { BomColumn, BomMetaColumn, BomTable, CreateBomColumnDto, CreateBomTableDto, UpdateBomTableDto } from "@rollup-api/models/bom";
import { updateBomTable } from "@rollup-api/utils";
import { CatalogItemStore, ICatalogItem } from "@store/CatalogItem/CatalogItemStore";
import { getCatalogItemById, moveItemInRefArray } from "@utilities";
import { rollupClient } from "src/core/api";

import appStore from "../AppStore";

import { BomColumnStore, IBomColumn, IBomColumnMobxType } from "./BomColumnStore";

export enum BomTableLoadingStatus {
  Unloaded,
  Loading,
  Loaded,
  Error,
}

export type BomTableNode = {
  catalogItem: ICatalogItem;
};

type TCreateColumnData = { statusDefinition: string } | { propertyDefinition: string } | { metaColumn: BomMetaColumn };

export const BomTableStore = types
  .model("BomTable", {
    id: types.identifier,
    label: types.string,
    columnMap: types.map<IBomColumnMobxType>(types.late((): IAnyModelType => BomColumnStore)),
    columnArray: types.array(types.safeReference(types.late((): IAnyModelType => BomColumnStore))),
    sortBy: types.safeReference(types.late((): IAnyModelType => BomColumnStore)),
    groupBy: types.safeReference(types.late((): IAnyModelType => BomColumnStore)),
    rows: types.array(types.safeReference(CatalogItemStore)),
    selectedRows: types.array(types.string),
    previewItemId: types.optional(types.string, ""),
    updatedAt: types.optional(types.number, Date.now()),
    updatedBy: types.maybeNull(types.string),
  })
  .volatile(() => ({
    loadingStatus: BomTableLoadingStatus.Unloaded,
    tableGridApi: undefined as BomTableGridApi | undefined,
  }))
  .actions(self => ({
    setTableGridApi(tableGridApi?: BomTableGridApi | undefined) {
      self.tableGridApi = tableGridApi;
    },
    setPreviewItemId(catalogItemId = "") {
      self.previewItemId = catalogItemId;
    },
    patch(dto: UpdateBomTableDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "columnMap"];
      const updateKeys = Object.keys(dto);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, omit(dto, ["blocks"]));
        if (dto.rows) {
          self.rows = cast(dto.rows.filter(id => !!id));
        }
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    exportCsv() {
      if (self.tableGridApi) {
        try {
          self.tableGridApi.exportDataAsCsv();
        } catch (err) {
          showApiErrorToast(`Can't export ${self.label}`, err as Error);
        }
      } else {
        showApiErrorToast(`Can't export ${self.label}`);
      }
    },
    toggleRowSelection(id?: string) {
      if (!id) {
        return;
      }

      if (self.selectedRows.includes(id)) {
        self.selectedRows = cast(self.selectedRows.filter(r => r !== id));
      } else {
        self.selectedRows = cast([...self.selectedRows, id]);
      }
    },
    removeSelectedRows() {
      self.rows = cast(self.rows.filter(r => r && !self.selectedRows.includes(r.id)).map(r => r?.id));
    },
    cleaSelectedRows() {
      self.selectedRows = cast([]);
    },
    unload() {
      self.columnMap = cast({});
      self.columnArray = cast([]);
      self.rows = cast([]);
      self.sortBy = undefined;
      self.groupBy = undefined;
      self.loadingStatus = BomTableLoadingStatus.Unloaded;
    },
    populate(rto: BomTable) {
      self.label = rto.label;
      // Clean up existing maps and columns
      this.unload();

      // Populate rows & columns
      try {
        const rows = rto.rows || [];
        self.rows = cast(rows.filter(r => r && getCatalogItemById(r)));
        rto.columns
          ?.sort((a, b) => a.orderIndex - b.orderIndex)
          .forEach(col => {
            col = omitBy(col, isNull) as BomColumn;
            const createdCol = self.columnMap.put({ ...col, table: self.id });
            self.columnArray = cast([...self.columnArray, createdCol]);
          });
      } catch (err) {
        console.error(err);
        this.unload();
        self.loadingStatus = BomTableLoadingStatus.Error;
        return false;
      }

      if (rto.groupBy) {
        self.groupBy = self.columnMap.get(rto.groupBy);
      }
      if (rto.sortBy) {
        self.sortBy = self.columnMap.get(rto.sortBy);
      }

      self.loadingStatus = BomTableLoadingStatus.Loaded;
      return true;
    },
    reorderColumn(id: string, destinationId: string, columnOrder: string[], notify = true) {
      columnOrder.forEach((colId, index) => {
        self.columnMap.get(colId)?.setOrderIndex(index);
      });
      if (notify) {
        rollupClient.bomTables.reorderColumn(self.id, id, destinationId);
      }
    },
    moveColumnsByIds(id: string, destinationId: string) {
      const srcIndex = self.columnArray.findIndex(i => i.id === id);
      const destIndex = self.columnArray.findIndex(i => i.id === destinationId);

      moveItemInRefArray(self.columnArray, srcIndex, destIndex);
    },
    setLabel(label: string) {
      if (self.label !== label) {
        self.label = label;
        updateBomTable(self.id, { label });
      }
    },
    setRows(rows: string[], notify = true) {
      self.rows = cast(rows);
      if (notify) {
        updateBomTable(self.id, { rows });
      }
    },
    addRow(catalogItem: ICatalogItem) {
      if (catalogItem && isAlive(catalogItem) && !self.rows.includes(catalogItem)) {
        self.rows = cast([...self.rows.map(r => r?.id), catalogItem.id]);
        updateBomTable(self.id, { rows: self.rows.map(r => r?.id) as string[] });
        return true;
      }
      return false;
    },
    removeRow(id: string) {
      const existingRow = self.rows.find(r => r?.id === id);
      if (existingRow) {
        self.rows = cast(self.rows.filter(r => r?.id !== id).map(r => r?.id));
        updateBomTable(self.id, { rows: self.rows.map(r => r?.id).filter(r => !!r) as string[] });
        return true;
      }
      return false;
    },
    addExistingColumn(createColumnDto: CreateBomColumnDto) {
      self.columnMap.put({ ...createColumnDto, table: self.id });
      self.columnArray = cast([...self.columnArray, createColumnDto.id]);
    },
    addColumn(createColumnDto: TCreateColumnData, notify = true) {
      const newColumnDto = {
        id: uuidv4(),
        ...createColumnDto,
        orderIndex: self.columnArray.length,
      };

      const createdCol = self.columnMap.put({ ...newColumnDto, table: self.id });
      self.columnArray = cast([...self.columnArray, createdCol]);

      if (notify) {
        rollupClient.bomTables.createColumn(self.id, newColumnDto);
      }
    },
    removeColumn(columnId: string, notify = true) {
      if (!self.columnMap.has(columnId)) {
        return;
      }

      self.columnMap.delete(columnId);
      self.columnArray = cast(self.columnArray.slice().filter(c => c.id !== columnId));

      if (notify) {
        rollupClient.bomTables.removeColumn(self.id, columnId);
      }
    },
    moveRow(srcId: string, destId: string, notify = true) {
      if (!srcId || !destId) {
        return;
      }

      const srcIndex = self.rows.findIndex(a => a!.id === srcId);
      const destIndex = self.rows.findIndex(a => a!.id === destId);
      moveItemInRefArray(self.rows, srcIndex, destIndex);

      if (notify) {
        updateBomTable(self.id, { rows: self.rows?.map(r => r!.id) });
      }
    },
  }))
  .views(self => ({
    get columns(): IBomColumn[] {
      return self.columnArray.slice().sort((a, b) => a.orderIndex - b.orderIndex);
    },
    get validRows(): ICatalogItem[] {
      return self.rows.filter(isAlive) as ICatalogItem[];
    },
    get rowIds(): string[] {
      return this.validRows.map(r => r.id);
    },
    // Cross-workspace items can appear if we allow users to view all the available PDM items from
    // the catalog items table & use them to extend existing BOM tables
    get crossWorkspaceParentlessItems(): ICatalogItem[] {
      return this.validRows.filter(r => r.workspaceId && r.workspaceId !== appStore.workspaceModel?.id && !r.parentItem);
    },
  }))
  .views(self => ({
    get rowNodes(): BomTableNode[] {
      return self.validRows.map(catalogItem => ({ catalogItem }));
    },
    get thumbnailColId(): string | undefined {
      return self.columns.find(c => c.metaColumn === BomMetaColumn.Thumbnail)?.id;
    },
    get metaColumns(): BomMetaColumn[] {
      return self.columns.filter(c => c.metaColumn).map(c => c.metaColumn) as BomMetaColumn[];
    },
  }))
  .actions(self => ({
    toggleThumbnail() {
      if (self.thumbnailColId) {
        self.removeColumn(self.thumbnailColId);
      } else {
        self.addColumn({ metaColumn: BomMetaColumn.Thumbnail });
      }
    },
  }));

export interface IBomTable extends Instance<typeof BomTableStore> {}
interface IBomTableSnapshotIn extends SnapshotIn<typeof BomTableStore> {}
interface IBomTableSnapshotOut extends SnapshotOut<typeof BomTableStore> {}
export interface IBomTableMobxType extends IType<IBomTableSnapshotIn, IBomTableSnapshotOut, IBomTable> {}

export function subscribeToBomTableEvents(socket: Socket) {
  socket.on("createBomTable", (data: { workspaceId: string; createBomTableDto: CreateBomTableDto }) => {
    if (data.createBomTableDto?.id && data.workspaceId === appStore.workspaceModel?.id) {
      appStore.workspaceModel.handleBomTableCreated(data.createBomTableDto);
    }
  });

  socket.on("deleteBomTable", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const bomTable = appStore.workspaceModel.bomTablesMap.get(data.id);
      if (bomTable) {
        if (data.id === appStore.env.activeBomTableId) {
          appStore.env.clearActiveBomTable();
        }
        appStore.workspaceModel.deleteBomTable(bomTable.id, false);
      }
    }
  });

  socket.on("updateBomTable", (data: { workspaceId: string; id: string; updateBomTableDto: UpdateBomTableDto }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const bomTable = appStore.workspaceModel.bomTablesMap.get(data.id);
      bomTable?.patch(data.updateBomTableDto);
    }
  });

  socket.on("addBomColumn", (data: { workspaceId: string; tableId: string; createBomColumnDto: CreateBomColumnDto }) => {
    if (data.tableId === appStore.env.activeBomTableId && data.workspaceId === appStore.workspaceModel?.id) {
      const bomTable = appStore.workspaceModel.bomTablesMap.get(data.tableId);
      bomTable?.addExistingColumn(data.createBomColumnDto);
    }
  });

  socket.on("deleteBomColumn", (data: { workspaceId: string; id: string }) => {
    if (data.id && appStore.env.activeBomTableId && data.workspaceId === appStore.workspaceModel?.id) {
      const bomTable = appStore.workspaceModel.bomTablesMap.get(appStore.env.activeBomTableId);
      bomTable?.removeColumn(data.id);
    }
  });
}
