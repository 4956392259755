import { observer } from "mobx-react";

import { BlockIcon, CustomIcon } from "@components/CustomIcon";
import { BlockType } from "@store/BlockStore";

type Props = {
  types: BlockType[];
};

const getBlockTypeIcon = (types: BlockType[]): BlockIcon | undefined => {
  if (types.includes(BlockType.Product)) {
    return BlockIcon.Product;
  } else if (types.includes(BlockType.Assembly)) {
    return BlockIcon.Assembly;
  } else if (types.includes(BlockType.Subsystem)) {
    return BlockIcon.Subsystem;
  } else if (types.includes(BlockType.Part)) {
    return BlockIcon.Part;
  }
};

const BlockTypeIcon = (props: Props) => {
  const { types } = props;
  const icon = getBlockTypeIcon(types);

  return icon ? <CustomIcon icon={icon} active /> : null;
};

export default observer(BlockTypeIcon);
