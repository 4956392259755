import { observer } from "mobx-react";

import { IViewer, PresenceAvatar } from "@components/Header/PresenceList/PresenceAvatar";
import appStore from "@store/AppStore";

import styles from "./PresenceList.module.scss";

interface IPresenceListProps {
  entityId?: string;
  viewers?: IViewer[];
}

const PresenceList = (props: IPresenceListProps) => {
  const { entityId } = props;
  const viewers = props.viewers ?? appStore.workspaceViewers.filter(viewer => viewer.entity?.id === entityId);

  if (!viewers?.length) {
    return null;
  }

  return (
    <div className={styles.avatarList}>
      {viewers.map(v => (
        <PresenceAvatar key={v.clientId} viewer={v} />
      ))}
    </div>
  );
};

export default observer(PresenceList);
