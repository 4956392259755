import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, FormGroup, InputGroup, Intent, Tree, TreeNodeInfo } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { CustomIcon } from "@components/CustomIcon";
import appStore from "@store/AppStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";
import { getBlockById, getBomTableById } from "@utilities";
import { Text, TextVariant } from "src/ui/Text";

import { DialogLegacy } from "../index";

import styles from "./DialogEditBomTable.module.scss";

export type DialogBomSelectionsProps = {
  isOpen: boolean;
  existingTableId?: string;
  onClose: () => void;
};

const CatalogItemsTree = Tree.ofType<ICatalogItem>();

const DialogEditBomTable = ({ existingTableId, isOpen, onClose }: DialogBomSelectionsProps) => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const bomTable = existingTableId ? getBomTableById(existingTableId) : undefined;
  const [inputValue, setInputValue] = useState(bomTable?.label || "");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);
  const [nameError, setNameError] = useState(false);
  const [nodesError, setNodesError] = useState(false);

  const toggleSelection = (catalogItem: ICatalogItem, recursive = false) => {
    if (!catalogItem || !appStore.workspaceModel) {
      return;
    }

    if (selectedItems.includes(catalogItem.id)) {
      if (recursive) {
        // Remove this and all its children from selection
        setSelectedItems(selectedItems.filter(id => !getBlockById(id)?.pathIds.includes(catalogItem.id)));
      } else {
        setSelectedItems(selectedItems.filter(id => id !== catalogItem.id));
      }
    } else {
      if (recursive) {
        // Add this and all its children, preserving existing selection
        setSelectedItems(
          appStore.orgModel.catalogItems.catalogItems
            .filter(b => b === catalogItem || selectedItems.includes(b.id) || b.pathIds.includes(catalogItem.id))
            .map(b => b.id)
        );
      } else {
        setSelectedItems([...selectedItems, catalogItem.id]);
      }
    }
  };

  const resetLabel = useCallback(() => setInputValue(bomTable?.label || ""), [bomTable?.label]);

  useEffect(() => {
    resetLabel();
  }, [isOpen, resetLabel]);

  useEffect(() => {
    if (existingTableId && bomTable?.validRows.length) {
      setSelectedItems(bomTable?.validRows.map(b => b.id));
    }
  }, [bomTable, existingTableId, isOpen]);

  const handleClearInput = () => {
    setInputValue("");
    inputRef.current?.focus();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (nameError) {
      setNameError(false);
    }
  };

  const resetStates = () => {
    setInputValue("");
    setSelectedItems([]);
    setCollapsedItems([]);
    setNameError(false);
    setNodesError(false);
  };

  const handleClose = () => {
    onClose();
    resetStates();
  };

  const handleConfirm = async () => {
    if (!inputValue) {
      setNameError(true);
      inputRef.current?.focus();
    }

    if (!inputValue || !appStore.workspaceModel) {
      !appStore.workspaceModel && onClose();
      return;
    }

    handleClose();

    if (bomTable) {
      bomTable.label !== inputValue && bomTable.setLabel(inputValue);
      bomTable.setRows(selectedItems);
    } else {
      const newBomTableId = await appStore.workspaceModel.createBomTable(inputValue, selectedItems);
      navigate(`/workspaces/${appStore.workspaceModel.id}/pdm/bom/${newBomTableId}`);
      appStore.env.setActiveBomTable(newBomTableId);
    }
  };

  const handleNodeClicked = (node: TreeNodeInfo<ICatalogItem>, _path: number[], ev: React.MouseEvent<HTMLElement>) => {
    if (node.nodeData) {
      toggleSelection(node.nodeData, ev.shiftKey || ev.ctrlKey || ev.metaKey);
      if (nodesError) {
        setNodesError(false);
      }
    }
  };

  const toggleExpand = (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.stopPropagation();

    if (collapsedItems.includes(id)) {
      setCollapsedItems(collapsedItems.filter(i => i !== id));
    } else {
      setCollapsedItems([...collapsedItems, id]);
    }
  };

  const renderLabel = (catalogItem: ICatalogItem) => {
    const path = catalogItem.pathIds.length - 1;
    const expanded = !collapsedItems.includes(catalogItem.id);
    const hasChild = !!catalogItem.validatedChildren.length;
    const paddingLeft = path * 30 - (hasChild ? 4 : 0);

    return (
      <div className={styles.dialogEditBomTableItemWrap}>
        <Checkbox checked={selectedItems.includes(catalogItem.id)} />
        <div className={styles.dialogEditBomTableItem} style={{ paddingLeft }}>
          {hasChild && (
            <Button
              onClick={e => toggleExpand(e, catalogItem.id)}
              small
              minimal
              icon={expanded ? "caret-down" : "caret-right"}
              e2eIdentifiers={["dialog-edit-bom-table", "toggle-expand"]}
            />
          )}
          {catalogItem.icon && <CustomIcon icon={catalogItem.icon} />}
          {catalogItem.name}
        </div>
      </div>
    );
  };

  const convertToTreeNode = (catalogItem: ICatalogItem) => {
    const node: TreeNodeInfo<ICatalogItem> = {
      id: catalogItem.id,
      isExpanded: !collapsedItems.includes(catalogItem.id),
      hasCaret: false,
      isSelected: selectedItems?.includes(catalogItem.id),
      label: renderLabel(catalogItem),
      nodeData: catalogItem,
    };

    if (catalogItem.validatedChildren) {
      node.childNodes = [];
      for (const b of catalogItem.validatedChildren) {
        node.childNodes.push(convertToTreeNode(b));
      }
    }
    return node;
  };

  const nodes: TreeNodeInfo<ICatalogItem>[] = appStore.workspaceModel
    ? appStore.orgModel.catalogItems.getParentlessWorkspaceItems(appStore.workspaceModel.id).map(convertToTreeNode)
    : [];

  const detachedCatalogItems = [
    ...(appStore.orgModel?.catalogItems.parentlessOrgWideItems || []),
    ...(bomTable?.crossWorkspaceParentlessItems || []),
  ].map(convertToTreeNode);

  const title = existingTableId ? "Edit BOM selection" : "Create new BOM";

  return (
    <DialogLegacy className={styles.dialogEditBomTable} isOpen={isOpen} title={title} onClose={handleClose}>
      <div className={styles.dialogEditBomTableBody}>
        <FormGroup>
          <label className={styles.dialogEditBomTableLabel} htmlFor="bomTableName">
            BOM table name*
          </label>
          <InputGroup
            inputRef={inputRef}
            autoFocus
            required
            id="bomTableName"
            value={inputValue}
            onChange={handleInputChange}
            large
            placeholder="Enter table name..."
            rightElement={
              inputValue ? (
                <Button minimal icon="delete" onClick={handleClearInput} e2eIdentifiers={["dialog-edit-bom-table", "reset-label"]} />
              ) : undefined
            }
            onKeyDown={event => {
              if (event.key === "Enter") {
                handleConfirm();
              }
            }}
          />
          {nameError && <div className={styles.dialogEditBomTableError}>Enter the BOM table name</div>}
        </FormGroup>
        <FormGroup className={styles.dialogEditBomTableBlockTreeContainer}>
          <span className={styles.dialogEditBomTableLabel}>Select catalog items to import</span>
          <Text variant={TextVariant.Caption} disableAlignmentWrapper>
            Hold shift to select all child blocks underneath a selected block
          </Text>
          <CatalogItemsTree className={styles.dialogEditBomTableBlockSelectionTree} contents={nodes} onNodeClick={handleNodeClicked} />
        </FormGroup>
        {detachedCatalogItems?.length ? (
          <FormGroup className={styles.dialogEditBomTableBlockTreeContainer}>
            <Text disableAlignmentWrapper>Org-wide catalog items:</Text>
            <CatalogItemsTree
              className={styles.dialogEditBomTableBlockSelectionTree}
              contents={detachedCatalogItems}
              onNodeClick={handleNodeClicked}
            />
          </FormGroup>
        ) : null}
      </div>
      <div className={styles.dialogEditBomTableFooter}>
        <Button outlined large onClick={onClose} e2eIdentifiers={["dialog-edit-bom-table", "cancel"]}>
          Cancel
        </Button>
        <Button large intent={Intent.PRIMARY} onClick={handleConfirm} e2eIdentifiers={["dialog-edit-bom-table", "confirm"]}>
          {existingTableId ? "Update" : "Create"}
        </Button>
      </div>
    </DialogLegacy>
  );
};

export default observer(DialogEditBomTable);
