import { IRollupEvent, RollupEventActions } from "@store/RollupEventsStore";

export const getEventName = (rollupEvent: IRollupEvent): string => {
  switch (rollupEvent.entityType) {
    case "comment": {
      switch (rollupEvent.action) {
        case RollupEventActions.mentionedInComment: {
          return "Mentioned by";
        }
        case RollupEventActions.commentCreated: {
          return "Comment from ";
        }
        case RollupEventActions.commentUpdated: {
          return "Comment updated by ";
        }
        case RollupEventActions.repliedToComment: {
          return "Reply from ";
        }
        case RollupEventActions.commentDeleted: {
          return "Comment deleted by ";
        }
        default:
          return "Action by ";
      }
    }
    default:
      return "Action from ";
  }
};
