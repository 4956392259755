import { ExecutionEnvironmentType } from "@rollup-api/models/execution-environments";

export const templateCode = new Map<ExecutionEnvironmentType, string>([
  [
    ExecutionEnvironmentType.Python,
    `# Import the rollup package for reading inputs and writing outputs
import rollup

# inputs are found under the rollup.inputs dictionary
# Note: assign linked properties to the code inputs on the side menu
c = rollup.inputs.a + rollup.inputs.b + 10
print(c)

# outputs are under the rollup.outputs.dictionary
rollup.outputs.c = c

# Example plot adapted from matplotlib gallery
# https://matplotlib.org/stable/gallery/lines_bars_and_markers/bar_colors.html
import matplotlib.pyplot as plt
fig, ax = plt.subplots()

fruits = ['apple', 'blueberry', 'cherry', 'orange']
counts = [40, 100, 30, 55]
bar_labels = ['red', 'blue', '_red', 'orange']
bar_colors = ['tab:red', 'tab:blue', 'tab:red', 'tab:orange']

ax.bar(fruits, counts, label=bar_labels, color=bar_colors)

ax.set_ylabel('fruit supply')
ax.set_title('Fruit supply by kind and color')
ax.legend(title='Fruit color')

# plots must be saved to file to appear as artifacts in the tab below
plt.savefig("chart.png")
plt.savefig("chart.pdf")`,
  ],
  [
    ExecutionEnvironmentType.Bash,
    `# Inputs are parsed from the args.json file
# Note: assign linked properties to the code inputs on the side menu
INPUT_A=$(jq .inputs.a "args.json")
INPUT_B=$(jq .inputs.b "args.json")
echo $INPUT_A, $INPUT_B

# Basic calculations
OUTPUT_C=$(expr $INPUT_A + $INPUT_B + 10)
echo $OUTPUT_C

# Write the output to output.json
echo $(jq -n "{ c: $OUTPUT_C }") > output.json
cat output.json

# Plotting requires gnuplot dependency in environment
# Use settings -> execution environments to create a custom environment
# gnuplot -e "set term png; set output 'output.png'; plot sin(x)"`,
  ],
  [
    ExecutionEnvironmentType.Node,
    `// Read inputs
const fs = require("fs");
const inputObjects = JSON.parse(fs.readFileSync("args.json"));

// Note: assign linked properties to the code inputs on the side menu
const {a, b} = inputObjects.inputs;
console.log(a, b)

// Generate output
const c = a + b + 10;
console.log(c)

// Write output to string
const outputString = JSON.stringify({c});
console.log(outputString);
fs.writeFileSync("output.json", outputString);`,
  ],
  [
    ExecutionEnvironmentType.Bun,
    `// Read inputs
const file = Bun.file("/app/rollup/args.json");
const args = await file.json();

// Note: assign linked properties to the code inputs on the side menu
const {a, b} = args.inputs;
console.log(a, b)

// Generate output
const c = a + b + 10;
console.log(c)

// Write output to file
const outputString = JSON.stringify({c});
console.log(outputString);
await Bun.write("output.json", outputString);`,
  ],
  [
    ExecutionEnvironmentType.Julia,
    `using JSON

# Read inputs
args = JSON.parsefile("args.json")

# Note: assign linked properties to the code inputs on the side menu
a = args["inputs"]["a"]
b = args["inputs"]["b"]

c = a + b + 10
print(c)

# Write outputs to a JSON file
outputs = Dict("c" => c)
open("output.json", "w") do f
    JSON.print(f, outputs)
end
  `,
  ],
  [
    ExecutionEnvironmentType.Matlab,
    `% read arguments from JSON file
args = jsondecode(fileread("args.json"));

% Note: assign linked properties to the code inputs on the side menu
a = args.inputs.a;
b = args.inputs.b;

c = a + b + 10;
disp(c);

% Write outputs to a JSON file
outputString = jsonencode(struct('c', c))
fid = fopen("output.json", "w");
fprintf(fid, outputString);
fclose(fid);

% Example plot using gnuplot output
% Warnings will be generated but they can be safely ignored
f = figure('visible','off')
clf ();
surf (peaks);

% plots must be saved to file to appear as artifacts in the tab below
print -f1 figure1.png;
print -f1 figure1.pdf;`,
  ],
]);
